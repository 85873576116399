import { Box, CircularProgress } from '@material-ui/core';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import React, { lazy, Suspense } from 'react';

import { initGoogleAnalytics } from './GoogleAnalytics';
import Layout from './Layout';
import theme from './theme';

const Home = lazy(() => import('./Home'));
const Jobs = lazy(() => import('./Jobs'));
const LegalNotices = lazy(() => import('./LegalNotices'));
const GoogleAnalytics = lazy(() => import('./GoogleAnalytics'));
const JoinUs = lazy(() => import('./JoinUs'));

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          <Suspense
            fallback={
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            }
          >
            {initGoogleAnalytics() && <GoogleAnalytics />}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/legal-notices" component={LegalNotices} />
              <Route exact path="/join-us" component={JoinUs} />
              <Route path="/jobs" component={Jobs} />
            </Switch>
          </Suspense>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
