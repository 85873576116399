import { useHistory, useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';

export default function useHashScroll() {
  const location = useLocation();
  const history = useHistory();
  const hash = location?.hash?.replace('#', '') || '';

  useLayoutEffect(() => {
    if (hash.length > 0) {
      document?.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
      history.push(location.pathname);
    }
  }, [hash, location.pathname, history]);
}
