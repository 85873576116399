import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Link,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GitHub, LinkedIn } from '@material-ui/icons';
import { element, func } from 'prop-types';

import useHashScroll from './useHashScroll';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    marginRight: theme.spacing(2),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  content: {
    minHeight: '100vh',
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  footer: {
    padding: theme.spacing(4),
  },
  grey: {
    color: theme.palette.grey[400],
  },
  header: {
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: func,
};

function Layout({ children }) {
  const classes = useStyles();
  useHashScroll();

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <AppBar elevation={0} className={classes.header}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item>
                <RouterLink to="/">
                  <Box component="img" padding={2} src="/logo.png" alt="Ctrl Up Logo" />
                </RouterLink>
              </Grid>
              <Grid item xs />
              <Grid item>
                <nav>
                  <Button variant="text" color="primary" size="large" component={RouterLink} to="/join-us">
                    Pourquoi Ctrl Up ?
                  </Button>
                  <Button variant="text" color="primary" size="large" component={RouterLink} to="/jobs">
                    Rejoindre l'équipe
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    size="medium"
                    href="mailto:contact@ctrlup.io?subject=Hello Ctrl Up ! 👋"
                    target="_blank"
                  >
                    Nous contacter
                  </Button>
                  <Button variant="text" color="secondary" size="large" href="https://www.ctrlup.io" target="_blank">
                    ctrlup.io
                  </Button>
                </nav>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <Toolbar />
      <Container component="main" maxWidth="lg">
        <div className={classes.content}>{children}</div>
        <Toolbar component="footer">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Box component="img" padding={2} src="./logo.png" alt="Ctrl Up Logo" />
                </Grid>
                <Grid item xs />
                <Grid item>
                  <nav>
                    <Button variant="text" color="primary" size="large" component={RouterLink} to="/join-us">
                      Pourquoi Ctrl Up ?
                    </Button>
                    <Button variant="text" color="primary" size="large" component={RouterLink} to="/jobs">
                      Rejoindre l'équipe
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      size="medium"
                      href="mailto:contact@ctrlup.io?subject=Hello Ctrl Up ! 👋"
                      target="_blank"
                    >
                      Nous contacter
                    </Button>
                    <Button variant="text" color="secondary" size="large" href="https://www.ctrlup.io" target="_blank">
                      ctrlup.io
                    </Button>
                  </nav>
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography className={classes.grey}>© 2023 Ctrl Up</Typography>
                </Grid>
                <Grid item>
                  <Button variant="text" color="secondary" size="small" component={RouterLink} to="/legal-notices">
                    Mentions légales
                  </Button>
                </Grid>
                <Grid item xs />
                <Grid
                  className={classes.icon}
                  item
                  component={Link}
                  href="https://www.linkedin.com/company/ctrlup/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedIn color="primary" />
                </Grid>
                <Grid
                  className={classes.icon}
                  item
                  component={Link}
                  href="https://github.com/ctrlup-io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHub fontSize="small" color="primary" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </>
  );
}

export default Layout;
