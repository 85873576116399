import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route, useLocation } from 'react-router-dom';

export const initGoogleAnalytics = () => {
  const isGAEnabled = process.env.NODE_ENV === 'production';

  if (isGAEnabled) {
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
  }

  return isGAEnabled;
};

function log(pathname, search = '') {
  const page = pathname + search;
  const { location } = window;
  ReactGA.set({
    page,
    location: `${location.origin}${page}`,
  });
  ReactGA.pageview(page);
}

function RouteTracker() {
  const { pathname, search } = useLocation();
  useEffect(() => {
    log(pathname, search);
  }, [pathname, search]);
  return null;
}

const GoogleAnalytics = () => <Route component={RouteTracker} />;

export default GoogleAnalytics;
