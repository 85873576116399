import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const PINK = '#FB84C6';

const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      h1: {
        fontWeight: 700,
      },
      h2: {
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
      },
      h5: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 700,
      },
      button: {
        fontWeight: 700,
      },
    },
    palette: {
      type: 'dark',
      primary: { main: PINK, contrastText: '#FFFDF6' },
      secondary: { main: '#FFFDF6', contrastText: '#030617' },
      background: {
        default: '#030617',
      },
      text: {
        primary: '#FFFDF6',
      },
    },
    shape: {
      borderRadius: 24,
    },
    overrides: {
      MuiLink: {
        root: {
          fontWeight: 700,
        },
      },
      MuiPaper: {
        outlined: {
          borderColor: PINK,
          borderWidth: 2,
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          fontWeight: 700,
          padding: '6px 24px',
          letterSpacing: 0.6,
        },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
        outlinedPrimary: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
        outlinedSecondary: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
    },
  }),
);

export default theme;
